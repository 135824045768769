<template>
    <div class="order_manage_view content-wrap">
      <div class="content-select">
        <div class="select-content">
          <el-form
            ref="selectFrom"
            :model="queryParams"
            class="select-form"
            :inline="true"
            label-width="100px"
          >
            <el-form-item label="销方名称" for="sellerOrgId">
              <organization-select
                :is-add-all="false"
                all-label="请选择销方主体"
                clearable
                filterable
                :model="queryParams"
              ></organization-select>
            </el-form-item>
  
            <el-form-item label="统计方式" >
                <el-select v-model="queryParams.selectedStatType" @change="handleStatTypeChange">
                    <el-option label="按月度统计" value="monthly"></el-option>
                    <el-option label="按季度统计" value="quarterly"></el-option>
                    <el-option label="按年度统计" value="yearly"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="月份" v-if="this.queryParams.selectedStatType ==='monthly'">
                <el-date-picker
                    v-model="queryParams.queryMonth"
                    type="month"
                    format="yyyy-MM"
                    value-format="yyyy-MM"
                    placeholder="选择月">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="年份" v-if="this.queryParams.selectedStatType !='monthly'">
                <el-date-picker
                v-model="queryParams.queryYear"
                type="year"
                format="yyyy"
                value-format="yyyy"
                placeholder="选择年">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="季度" v-if="this.queryParams.selectedStatType === 'quarterly'">
                <el-select v-model="queryParams.selectedQuarter">
                    <el-option label="第一季度" value="firstQuarter"></el-option>
                    <el-option label="第二季度" value="secondQuarter"></el-option>
                    <el-option label="第三季度" value="thirdQuarter"></el-option>
                    <el-option label="第四季度" value="fourthQuarter"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="发票种类" for="billingType">
                <el-select
                v-model="queryParams.billingType"
                placeholder="请选择开票类型"
                @keyup.enter.native="handleQuery"
                clearable
              >
                <el-option
                  v-for="item in dictionary.invoiceBillingType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-left: 32px">
              <select-button
                @select="handleQuery"
                @reset="handleReset"
              ></select-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="content-middle">
        <el-row>
        <el-col :span="12">
          <!-- <p>正数发票份数(含作废): {{positiveInvoiceCountByQuarter}}份</p>
          <p>正数废票份数: {{positiveWasteInvoiceCountByQuarter}}份</p>
          <p>负数废票份数: {{negativeWasteInvoiceCountByQuarter}}份</p> -->

          <span>正数发票份数: {{ positiveInvCount }}份</span>
        </el-col>
        <el-col :span="12">
          <span>负数发票份数: {{ negativeInvCount }}份</span>
        </el-col>
      </el-row>
      </div>


      <div class="content-main">
        <div>
          <div class="content-table">
            <el-table
              ref="invoiceCenterTable"
              :data="dataList"
              stripe
              border
              v-loading="dataLoading"
              :header-cell-style="handleHeaderCellStyle"
            >
              <el-table-column
                type="index"
                width="80"
                label="序号"
                fixed="left"
                align="center"
              />
     
              <el-table-column
                prop="projectName"
                label="项目名称"
                min-width="200"
                show-overflow-tooltip
              />
              <el-table-column
                prop="total"
                label="合计"
                min-width="160"
              />
             
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import OrganizationSelect from "@/components/widgets/OrganizationSelect";
  import { getStatistics,getInvCount } from '@/service/sal/invoice.js'
  import {
    tableStyle,
    pagingMethods,
    dateOption,
    changeMethods,
  } from "@/util/mixins";
  export default {
    name: "SalInvoicingStatistics",
    mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
    components: { OrganizationSelect },
    data() {
      return {   
        queryParams: {
          orgId: JSON.parse(localStorage.getItem("orgId" || "")),
          billingType: undefined,
          selectedStatType:"monthly",
          queryMonth: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0'),
        },
        positiveInvCount:0,
        negativeInvCount:0,
        dictionary: {
          invoiceBillingType: [],
        },
        dictionaryLoading: false,
        dataList: [],
        dataLoading: false,
        dataAggr: {
          total: 0,
          sheetSumAmount: 0,
          invoicedTimes: 0,
          invoicedSumAmount: 0,
          // 添加新的属性来存储后端返回的各列数据
          positiveAmountSum: 0,
          negativeAmountSum: 0,
          positiveTaxSum: 0,
          negativeTaxSum: 0,
        },
      };
    },
    async created() {
      this.queryParams.orgId = JSON.parse(localStorage.getItem("orgId" || ""));
      await this.initDict();
      this.handleQuery();
    },
    methods: {
      // 初始字典数据
      async initDict() {
        this.dictionaryLoading = true;
        this.dictionary.invoiceBillingType =
        this.$store.state.enums["BillingType"] || [];
        this.dictionaryLoading = false;
      },

      // 订单列表查询
      handleQuery() {
   
        // this.queryParams.pageNo = 1;
        this.getStatisticsList();
        this.getInvCountTotal();
      },
      handleStatTypeChange(){
      // 根据统计方式设置查询参数
      if (this.queryParams.selectedStatType ==='monthly') {
          this.queryParams.queryYear = '';
          this.queryParams.selectedQuarter = '';
          this.queryParams.queryMonth =new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0');
        } else if (this.queryParams.selectedStatType === 'quarterly') {
          this.queryParams.queryMonth = '';
          this.queryParams.queryYear = new Date().getFullYear().toString();
          this.queryParams.selectedQuarter='firstQuarter'
        } else if (this.queryParams.selectedStatType === 'yearly') {
          this.queryParams.queryMonth = '';
          this.queryParams.selectedQuarter = '';
          this.queryParams.queryYear = new Date().getFullYear().toString();
        }
      },
      async getStatisticsList(){
        // let pageArg = Object.assign({}, this.queryParams);
        const {success,data} = await getStatistics(this.queryParams);
        if(success){
         // 将后端返回的数据填充到相应的属性中
          this.dataAggr.positiveAmountSum = data.positiveAmountSum;
          this.dataAggr.negativeAmountSum = data.negativeAmountSum;
          this.dataAggr.positiveTaxSum = data.positiveTaxSum;
          this.dataAggr.negativeTaxSum = data.negativeTaxSum;

         // 构建 dataList
          this.dataList = [
            {
              projectName: '销项正数金额',
              total: this.dataAggr.positiveAmountSum,
            },
            {
              projectName: '销项负数金额',
              total: this.dataAggr.negativeAmountSum,
            },
            {
              projectName: '销项正数税额',
              total: this.dataAggr.positiveTaxSum,
            },
            {
              projectName: '销项负数税额',
              total: this.dataAggr.negativeTaxSum,
            },
          ];
          console.log(JSON.stringify(data));
        }
      },
      async getInvCountTotal(){
        const {success,data} = await getInvCount(this.queryParams);
        if(success){
          this.positiveInvCount=data.positiveInv
          this.negativeInvCount=data.negativeInv
        }
      },
      // 重置查询条件
      handleReset() {
        this.queryParams = {
          orgId: undefined,
          billingType: undefined,
          queryMonth: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0'),
          queryYear: new Date().getFullYear().toString(), // 年份默认展示本年度
          selectedQuarter:"firstQuarter",
          selectedStatType:"monthly"
        };
      },
  
    },
  };
  </script>
  <style lang="scss" scoped>
  .order_manage_view {
    .table_total_box {
      display: flex;
      padding: 16px 10px;
      background: #ebf4ff;
      span {
        padding-right: 40px;
      }
    }
  }
  
  @import "@/style/select.scss";
  
  .select-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px 24px;
  
    .select-items {
      display: inline-flex;
  
      div {
        color: #3d94ff;
        margin-right: 20px;
        font-weight: 500;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        line-height: 22px;
  
        img {
          width: 20px;
          height: 20px;
          vertical-align: sub;
          margin-right: 4px;
        }
      }
  
      div:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  
    .select-title span:before {
      content: "|";
      display: inline-block;
      font-size: 16px;
      margin-right: 10px;
      background-color: #333;
    }
  }
  
  .content-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100% - 180px);
  
    .main-top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
  
      .top-left {
        display: flex;
        justify-content: flex-start;
  
        span {
          line-height: 32px;
          font-size: 16px;
          color: #666666;
        }
  
        .el-button {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1890ff;
          line-height: 22px;
        }
  
        .rowSet {
          display: flex;
          align-items: center;
          cursor: pointer;
  
          .imgSet {
            width: 20px;
            height: 20px;
            margin-right: 2px;
          }
  
          span {
            color: #1890ff;
          }
        }
      }
    }
  
    .content-table {
      .el-button {
        padding: 0;
        margin-right: 10px;
      }
    }
  }
  
  .manual-input {
    .el-row {
      margin-bottom: 20px;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    .el-col {
      text-align: left;
      border-radius: 4px;
  
      ::v-deep .el-select > .el-input,
      .el-input {
        width: 250px;
      }
    }
  }
  
  .phone-scan {
    .phone-scan-text {
      display: flex;
      text-align: left;
      flex-direction: column;
      align-items: flex-start;
  
      span {
        margin-bottom: 12px;
      }
    }
  
    .phone-scan-img {
      .el-image {
        width: 260px;
        height: 260px;
      }
    }
  }
  
  .invoice-update {
    .el-row {
      margin-bottom: 20px;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    .el-col {
      text-align: left;
      border-radius: 4px;
    }
  }
  
  .el-image-viewer {
    position: absolute;
    z-index: 1000;
  }
  
  .dialog-title {
    display: flex;
    justify-content: space-between;
    margin-top: -24px;
    margin-bottom: 24px;
  
    .title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
    }
  
    .message {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  
  
  ::v-deep .el-dialog__header {
    padding: 24px 24px 0px 24px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }
  
  ::v-deep .el-dialog__body {
    padding: 24px 20px;
    color: #333333;
    font-size: 14px;
    word-break: break-all;
  }
  
  ::v-deep .el-form-item__error {
    padding-top: 0;
  }
  
  ::v-deep .el-dialog__footer {
    padding: 0px 20px 20px;
    text-align: right;
    box-sizing: border-box;
  }
  
  .invoice-deleted {
    position: absolute;
    bottom: 0;
    margin-left: -24px;
    height: 48px;
    z-index: 9;
    width: calc(100% - 200px);
    line-height: 48px;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
  }
  
  .invoice-deleted-hidden {
    position: absolute;
    bottom: 0;
    margin-left: -24px;
    height: 48px;
    z-index: 9;
    width: calc(100% - 64px);
    line-height: 48px;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
  }
  
  .dialog-wrap {
    span {
      color: #333333;
    }
  
    .deletes-btns {
      padding-top: 24px;
      text-align: right;
    }
  }
  
  .part {
    width: 100%;
    height: 1px;
    background: #e5e5e5;
    margin: 15px 0;
  }
  
  .icon {
    color: #ff0000;
    margin-left: 3px;
  }
  
  .button-footer {
    text-align: center;
  
    .button-left {
      margin-right: 65px;
    }
  }
  
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  
  .el-icon-arrow-down {
    font-size: 12px;
  }
  
  .train21No {
    margin-right: 31px;
    margin-left: -31px;
    .el-input {
      width: 150px;
    }
  }
  
  .select-content {
    ::v-deep .select-form {
      width: auto;
      display: block;
    }
  
    ::v-deep .select-form > div {
      display: inline-flex;
    }
  }
  
  ::v-deep .el-range-editor--mini .el-range-separator {
    line-height: inherit;
  }
  ::v-deep .content-middle {
    padding: 10px;
    margin: 20px 0;
    border: 1px solid #e9e9e9;
    background-color: #ffffff;
    span {
        font-size: 14px;
        color: #666;
        display: block;
        margin-bottom: 5px;
    }
}
  </style>
  