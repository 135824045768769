import {del, download, get, post, put} from '@/service/index.js';

// 发票回写
export const invoiceReturn = (param) => {
  return post('/sal/invoice/return', param).then((res) => res);
};

// 分页查询待开发票
export const waitList = (param) => {
  return get('/sal/invoice/list/wait', param).then((res) => res);
};

// 汇总查询待开发票
export const waitSummary = (param) => {
  return get('/sal/invoice/summary/wait', param).then((res) => res);
};

// 获取发票库存信息
export const getInventory = (data) => {
  return get('/sal/invoice/inventory', data).then((res) => res);
};

// 请求开具指定发票
export const billInvoice = (data) => {
  return post('/sal/invoice/bill', data).then((res) => res);
};

// 获取发票明细列表
export const selectItems = (id) => {
  return get(`/sal/invoice/items/${id}`).then((res) => res);
};
// 发票明细编辑
export const invoiceDetailRedact = (data) => {
  return put('/sal/invoice/item/',data).then((res => res))
};
// 发票明细同步
export const editItemAmount = (data) => {
  return post('/sal/invoice/item/edit/amount', data).then((res => res))
};
// 发票明细同步
export const sycnMerch2InvoiceItem = (data) => {
  return post('/sal/invoice/item/sync', data).then((res => res))
};
// 合并指定发票信息
export const mergeInvoice = (data) => {
  return post('/sal/invoice/merge', data).then((res) => res);
};

// 拆分指定发票信息
export const splitInvoice = (data) => {
  return post('/sal/invoice/split', data).then((res) => res);
};

// 分页查询开票记录
export const billList = (param) => {
  return get('/sal/invoice/list/bill', param).then((res) => res);
};

// 汇总查询开票记录
export const billSummary = (param) => {
  return get('/sal/invoice/summary/bill', param).then((res) => res);
};

// 汇总查询明细
export const invoiceItemsSummary = (data) => {
  return get('/sal/invoice/item/summary', data).then((res) => res);
};

// 获取发票信息详情
export const selectInvoice = (id) => {
  return get(`/sal/invoice/whole/${id}`).then((res) => res);
};

export const selectInvoiceDetail = (id) => {
  return get(`/sal/invoice/${id}`).then((res) => res);
};


/**
 * 全电红色通知单冲红
 * @param {*} data
 */
export const handleSyncToRedInvoiceStatus = (data) => {
  return post('/sal/invoice/allElectricRedTicketResultQuery', data).then(res => res)
}

/**
 * 发票入账状态
 * @param id
 * @returns {Promise<unknown>}
 */
export const getInvoiceChargeAccountStatus = (data) => {
  return get('/sal/invoice/charge/account/status', data).then((res) => res);
};

/**
 * 全电红色通知单冲红
 * @param {*} data
 */
export const handleElectricRedTicket = (data) => {
  return post('/invoice/redNotice/bill', data).then(res => res)
}

// 红冲指定发票信息
export const toredInvoice = (data) => {
  return post('/sal/invoice/tored', data).then((res) => res);
};

// 作废指定发票信息
export const revokeInvoice = (id) => {
  return post(`/sal/invoice/revoke/${id}`).then((res) => res);
};

// 打量打印发票信息
export const printInvoice = (data) => {
  return post('/sal/invoice/print', data).then((res) => res);
};
/**
 * 诺诺-打印发票
 * @param data
 * @returns {Promise<unknown>}
 */
export const nNPrintInvoice = (data) => {
  return post('/invoice/sal/nNPrintInvoice', data).then((res) => res);
};

// 重新开具指定发票
export const rebillInvoice = (id) => {
  return post(`/sal/invoice/rebill/${id}`).then((res) => res);
};
/**
 * 模糊搜索商家名称（发票抬头）
 * @param param
 * @returns {Promise<unknown>}
 */
export const getBuyerNames = (param) => {
  return get('/invoice/manual/getBuyerName', param).then((res) => res);
};

export const getBuyerTaxNo = (param) => {
  return get('/invoice/manual/getBuyerTaxNo', param).then((res) => res);
};

/**
 * 手工发票开具
 * @param data
 * @returns {Promise<unknown>}
 */
export const manualInvoiceIssue = (data) => {
  return post('/invoice/manual/billingManualInvoice', data).then((res) => res);
};

/**
 * 根据组织Id获取默认税盘
 * @param param
 * @returns {Promise<unknown>}
 */
export const getEquipmentByOrgId = (param) => {
  return get('/invoice/manual/getEquipmentByOrgId', param).then((res) => res);
};

/**
 * 开票记录导出
 * @param data
 * @returns {Promise<unknown>}
 */
export const exportAlreadyInvoices = (data) => {
  return download('/sal/invoice/export/bill', data).then((res) => res);
};

// 修改发票购方信息
export const updateInvoiceBuyer = (data) => {
  return put('/sal/invoice/buyer', data).then((res) => res);
};

// 下载发票影像文件
export const downloadInvoice = (id) => {
  return download(`/sal/invoice/download/image/${id}`).then((res) => res);
};

// 下载发票影像文件
export const downloadInvoiceFile = (data) => {
  return download('/sal/invoice/download/invoice/file', data).then((res) => res);
};

// 删除指定发票信息
export const deleteInvoice = (id) => {
  return del(`/sal/invoice/${id}`).then((res) => res);
};

// 批量删除发票信息
export const batchDeleteInvoice = (data) => {
  return del('/sal/invoice', data).then((res) => res);
};

export const relationCount = (id) => {
  return get(`/sal/invoice/relation/count/${id}`).then((res) => res);
};

// 检查是否超过限额
export const checkInvoiceLimit = (data) => {
  return post('/sal/invoice/limit/check', data).then((res) => res);
};

// 检查是否超过限额
export const manualSplitInvoiceLimit = (data) => {
  return post('/invoice/manual/limit/split', data).then((res) => res);
};

// 超过限额自动拆分
export const splitInvoiceLimit = (invoiceId) => {
  return get(`/sal/invoice/limit/split/${invoiceId}`).then((res) => res);
};

/**
 * 获取开票设置信息
 * @param param
 */
export const getBillingOpenPersonSetting = (param) => {
  return get('/invoice/manual/billingConfig', param).then((res) => res);
};

/**
 * 修改开票人设置信息
 * @param param
 * @returns {Promise<any | never>}
 */
export const saveOrUpdateBillingOpenPersonMsg = (param) => {
  return post('/invoice/manual/billingConfig', param).then((res) => res);
};

/**
 * 校验发票开具状态，返回开具方式
 * @param param
 */
export const checkBillingInvoiceStatusReturnbillingChannel = (param) => {
  return get(`/invoice/sal/checkBillingInvoiceStatus/${param}`).then((res) => res);
};

/**
 * 获取当前用户默认开票人设备
 * @param param
 * @returns {Promise<any | never>}
 */
export const getCurrentUserDefaultConfig = (param) => {
  return get('/invoice/manual/defaultConfig', param).then((res) => res);
};

/**
 * 分页查找红字信息表信息
 * @param param
 * @returns {Promise<any | never>}
 */
export const getRedNoticeListByPage = (param) => {
  return post('/invoice/redNotice/list', param).then((res) => res);
};

/**
 * 更具发票代码和号码查询发票信息
 * @param param
 * @returns {Promise<any | never>}
 */
export const getBillingInvoiceByCodeAndNo = (param) => {
  return get('/invoice/redNotice/billingInvoice', param).then((res) => res);
};

/**
 * 查询发票是否已申请过红字信息表
 * @param param
 */
export const getRedNoticeByBillingInvoiceId = (param) => {
  return get('/invoice/redNotice/redNotice/' + param).then((res) => res);
};

/**
 * 提交申请
 * @param param
 * @returns {Promise<any | never>}
 */
export const submitApply = (param) => {
  return post('/invoice/redNotice', param).then((res) => res);
};

/**
 * 查询审核状态
 * @param param
 * @returns {Promise<any | never>}
 */
export const getAuditStatus = (param) => {
  return get('/invoice/redNotice/auditStatus/' + param).then((res) => res);
};

/**
 * 获取已开发票信息
 * @param param
 * @returns {Promise<T>}
 */
export const getBillingInvoice = (param) => {
  return get(`/invoice/sal/${param}`).then((res) => res);
};

export const listEquipmentByOrg = (id) => {
  return get(`/equipment/org/list/${id}`).then((res) => res);
};

/**
 * 获取发票明细数量
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const getInvoiceItemNumbers = (data) => {
  return get('/invoice/sal/getInvoiceItemNumbers/' + data).then((res) => res);
};

/**
 * 获取红色通知单详情
 * @param {*} data
 */
export const getBillingRedNoticeDetails = (data) => {
  return get('/invoice/redNotice/billingRedNotice/details/' + data).then((res) => res);
};

// 下载发票导入模板
export const downloadTemplate = () => {
  return download('/sal/invoice/download/template').then((res) => res);
};

export const invoiceUploadBatch = (data) => {
  return post('/original/order/import', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => res);
};

// 批量开具发票信息
export const batchBillInvoice = (data) => {
  return post('/sal/invoice/batch/bill', data).then((res) => res);
};

// 打印指定发票信息
export const printInovice = (data) => {
  return post('/sal/invoice/print', data).then((res) => res);
};

// 查询发票图像
export const querySingleInvoice = (data) => {
  return get('/sal/invoice/querySingleInvoice', data).then((res) => res);
};

// 交付电子发票影像
export const deliverInvoice = (data) => {
  return post('/sal/invoice/deliver', data).then((res) => res);
};

// 同步发票开具状态
export const syncInvoice = (invoiceId) => {
  return post(`/sal/invoice/sync/${invoiceId}`).then((res) => res);
};

// 更新发票备注信息
export const updateInvoiceRemark = (param) => {
  return put('/sal/invoice/remark', param).then((res) => res);
};

// 代开发票-开具-查询
export const getRedNoticeByNumber = (param) => {
  return get(`/invoice/redNotice/getRedNoticeByNumber/${param}`).then((res) => res);
};

// 代开发票-开具-确定开具
// /sal/invoice/toRedValid
export const invoiceToRedValid = (param) => {
  return post('/sal/invoice/toRedValid', param).then((res) => res);
};

/**
 * 发票重新开具
 *
 * @param param
 * @returns {Promise<unknown>}
 */
export const reBillingInvoice = (invoiceId) => {
  return post(`/sal/invoice/reBillingInvoice/${invoiceId}`).then((res) => res);
};

/**
 * 【待开发票管理】修改发票类型
 */
export const updateBillingType = (data) => {
  return post('/sal/invoice/edit/billingType', data).then((res) => res);
};
/**
 * 同步畅捷通单据数据
 */
export const syncChanjetVoucherDataInferface = (sheetCode) => {
  return get(`/sal/ori/trade/item/syncChanjetVoucherData/${sheetCode}`).then((res) => res);
};

/**
 * 获取唯一订单编码
 */
export const getUniqueOrder = (data) => {
  return get('/invoice/manual/getOrderNo').then((res) => res);
};

/**
 * 【待开发票管理】修改发票组织
 */
export const updateInvoiceOrg = (data) => {
  return post('/sal/invoice/edit/invoice/org', data).then((res) => res);
};

/**
 * 导入发票文件
 * @param data
 * @returns {Promise<unknown>}
 */
export const uploadInvoiceOriginFile = (data) => {
  return post('/sal/invoice/upload', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => res);
};

/**
 * 全电开票导出
 * @param data
 * @returns {Promise<unknown>}
 */
export const rpaExport = (data) => {
  return download('/sal/invoice/rpaExport', data).then((res) => res);
};

/**
 * 全电结果导入
 * @param data
 * @returns {Promise<unknown>}
 */
export const rpaImport = (data) => {
  return post('/sal/invoice/rpaImport', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => res);
};

/**
 * 获取查询条件ids
 * @param data
 * @returns {Promise<unknown>}
 */
export const getQueryIds = (data) => {
  return post('/sal/invoice/getQueryIds', data).then((res) => res);
};

/**
 * 导入结果下载
 * @param data
 * @returns {Promise<unknown>}
 */
export const importResultExport = (data) => {
  return download('/sal/invoice/importResultExport/' + data).then((res) => res);
};

//批量下载 /invoice/download
export const invoiceDownload = (param) => {
  return download('/sal/invoice/downloadImageBatch', param).then((res) => res);
};

export const updateInvoiceSellerInfo = (param) => {
  return post('/sal/invoice/sync/seller/info', param).then((res) => res);
};

export const invoicePreviewImage = (id) => {
  return get(`/sal/invoice/preview/image/${id}`).then((res) => res);
};

// 蓝字发票待冲红列表
export const waitToRedInvoicePage = (data) => {
  return post('/sal/invoice/wait/tored/list', data).then((res) => res);
};

// 获取发票影像
export const getInvoiceImage = (id) => {
  return get(`/sal/invoice/invoice/image/${id}`).then((res) => res);
};

//选择蓝票 提交冲红
export const handleRedNoticeConfirm = (id) => {
  return get(`/sal/invoice/tored/confirm/${id}`).then((res) => res);
};
/**
 * item 校验
 * @param data
 * @returns {Promise<unknown>}
 */
export const invItemValid = (data) => {
  return post(`/sal/invoice/invItemValid`, data).then((res) => res);
};

/**
 * 发票重开
 * @param data
 * @returns {Promise<unknown>}
 */
export const reissue = (data) => {
  return post(`/sal/invoice/reissue`, data).then((res) => res);
};

/**
 * 查询待开total
 * @param data
 * @returns {Promise<unknown>}
 */
export const waitTotal = (data) => {
  return get(`/sal/invoice/list/wait/total`, data).then((res) => res);
};
export const waitExport = (data) => {
  return download(`/sal/invoice/waitExport`, data).then((res) => {
    let blob = new Blob([res.data], {type: 'application/xlsx'});
    let url = window.URL.createObjectURL(blob);
    const link = document.createElement('a'); // 创建a标签
    link.href = url;
    link.download = '待开发票导出模板.xlsx'; // 重命名文件
    link.click();
    URL.revokeObjectURL(url);
  });
};
// 获取发票明细列表
export const getSelectItems = (data) => {
  return get('/sal/invoice/item/list', data).then((res) => res);
};
// 冲红
export const redOffsetEntry = (id) => {
  return get('/sal/invoice/check/red/offset/entry',id).then((res) => res);
};

// 经营方式/system/label/page
export const systemLabelPage = (data) => {
  return post('/system/label/page', data).then((res) => res);
};


export const updateLabel = (data) => {
  return post('/sal/invoice/updateLabel', data).then((res) => res);
};

// 发票数据统计
export const getStatistics = (data) => {
  return post('/sal/invoice/getStatistics',data).then((res) => res);
};
//正负数发票份数
export const getInvCount = (data) => {
  return post('/sal/invoice/getStatistics/getInvCount',data).then((res) => res);
};